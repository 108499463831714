import React, { Component } from 'react';
//import { Link as Ir } from 'react-scroll';

const hoy = new Date(); 
const ano = hoy.getFullYear();

class Pie extends Component {
  render () {
    return (
      <div className="max_width padding_pie pie">
        {/*
        <center><h7>Siguenos en redes sociales:</h7></center>
        <div id="subir_sale"><br/></div>
        <Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={500} className="subir" title="Subir">▲</Ir>
        <div className="flex_redes">
          {
            window._my_redes.map((item, i) => 
              <a href={item.link} target="_new" className="de_flex_redes" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
            )
          }
        </div>
        <div id="cl"><br/><br/><br/></div>
        */}
         <a href={window.whats} target="new" className="whatsapp"><img src="img/r_whats-01.svg" alt="Whatsapp" title="Whatsapp"/></a>
        <div className="linea bg_blanco"></div>

        <div className="pie_flex">
          <div className="de_flex_pie pie_top">
            Todos los derechos reservados © {ano}
          </div>
          <div className="de_flex_pie at_right">
            Diseñado por<br/><a href="http://adsvega.com/" target="_new" className="grises"><img src={`${window.rutas}img/adsvega.svg`} title="fireComm" alt="fireComm" className="logo_pie"/></a>
          </div>
        </div>
      </div>
    )
  }
}

export default Pie;