import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Inicio from './components/Inicio'
import Pie from './components/Pie'
import Regalo from './components/Regalo'

class App extends Component {
  render () {
    return (
      <Router>
	      <Switch>
	        <Route exact path="/" component={Inicio} />
	        <Route path={window.path+"/regalo"} component={Regalo} />
	        <Route component={Inicio} />
	      </Switch>
	      <div id="cl"></div>
	      <Pie />
      </Router>
    )
  }
}

export default App;
