import React, { Component } from 'react';
import Axios from 'axios';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      telefono:'',
      asunto:'',
      mensaje:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick(e) {
    const {nombre, correo, mensaje} = this.state;
    if(nombre===''){
      window.alerta('error','Ingresa tu nombre completo');
    }else if(correo===''){
      window.alerta('error','Ingresa tu correo electrónico');
    }else if(window.validarMail(correo)===false){
      window.alerta('error','Ingrese un correo electrónico valido');
    }else if(mensaje===''){
      window.alerta('error','Escribe un mensaje');
    }else{
      window.window.H5_loading.show();
      let formData = new FormData(document.getElementById('formulario'));
      Axios.post(window.envia, formData)

        .then(function(response) {
          if(response.data===1){
            window.alerta('success','El mensaje ha sido enviado de manera satisfactoria, pronto nos pondremos en contacto');
          }else{
            window.alerta('error','Ha ocurrido un error intentalo más tarde');
          }
        })
        .then(function(texto) {
           window.H5_loading.hide();
        })
        .catch(err => console.error(err));
        this.setState({nombre:'',correo:'',mensaje:'',telefono:'',asunto:''});
    }
  }

  render () {
    return (
      <div className="contacto">
        <center><h11>CONTACTANOS</h11></center>
        <div id="cl"><br/><br/></div>
        <div className="pa_flex_2">
          <div className="de_flex_2 infcontacto" data-aos="fade-right">
            <h7>Información de contacto</h7>
            <ul className="list1">
              <li className="telefono">{window.telefono}</li>
              <li className="correo"><a href={`mailto:${window.correo}`}>{window.correo}</a></li>
              <li className="ubicacion">{window.ubicacion}</li>
            </ul>
            
            {/*<div id="cl"><br/></div><iframe src={window.mapa} className="mapa" title="Mapa" allowfullscreen/>*/}
          </div>
          <div className="de_flex_2" data-aos="fade-left">
            <form id="formulario">
              <h7>Afíliate</h7>
              <div id="cl"><br/></div>
              <input type="text" name="nombre" onChange={this.handleChange} placeholder="Nombre:" value={this.state.nombre}/>
              <input type="text" name="correo" onChange={this.handleChange} placeholder="Correo:" value={this.state.correo}/>
              
              <input type="text" name="telefono" onChange={this.handleChange} placeholder="Teléfono:" value={this.state.telefono}/>
              <input type="text" name="asunto" onChange={this.handleChange} placeholder="Asunto: ej. Cotización Tequilas" value={this.state.asunto}/>

              <textarea name="mensaje" id="area" onChange={this.handleChange} placeholder="Mensaje:" value={this.state.mensaje}/>                  
              <center><div onClick={this.handleClick} className="button puntero">ENVIAR</div></center>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Contacto;