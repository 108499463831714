import React, { Component } from 'react';
import { Link as Ir } from 'react-scroll';
import { Link } from 'react-router-dom'
import { Accordion, AccordionItem } from 'react-sanfona';

class Menu extends Component {
  render () {
    return (
      <div className="max_width menu_flex">
        <div className="loguito">
          <img src="img/logo.svg" alt="kanTina libre" title="kanTina libre" className="logo"/>
        </div>
        <div className="ir dropdown">
          <div className="dropbtn">Catálogo</div>
          <div class="dropdown-content">
            <Accordion allowMultiple={false}>
              <AccordionItem title="Tequilas +" className="dt2">
                <div id="cl"><br/></div>
                {
                  window._my_tequilas.map((item, i) =>
                    <Ir to={item.ir} activeClass="active" spy={true} smooth={true} duration={500} className="ir_n">{item.titulo}<div id="cl"></div><h12>{item.marca}</h12></Ir>
                  )
                }
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <Ir to="contacto" activeClass="active" spy={true} smooth={true} duration={500} className="ir">Contacto</Ir>
        <Link to="regalo" className="ir">Haz un regalo</Link>
      </div>
    )
  }
}

export default Menu;