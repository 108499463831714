import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Axios from 'axios';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      telefono:'',
      producto:'',
      cantidad:'1',
      mensaje:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick(e) {
    const {nombre, correo, telefono, producto, mensaje} = this.state;
    if(nombre===''){
      window.alerta('error','Ingresa tu nombre completo');
    }else if(correo===''){
      window.alerta('error','Ingresa tu correo electrónico');
    }else if(producto===''){
      window.alerta('error','Selecciona un producto');
    }else if(telefono===''){
      window.alerta('error','Ingresa tu teléfono');
    }else if(window.validarMail(correo)===false){
      window.alerta('error','Ingrese un correo electrónico valido');
    }else if(mensaje===''){
      window.alerta('error','Escribe un mensaje');
    }else{
      window.window.H5_loading.show();
      let formData = new FormData(document.getElementById('formulario'));
      Axios.post(window.envia2, formData)
        .then(function(response) {
          if(response.data===1){
            window.alerta('success','El regalo ha sido solicitado, pronto nos pondremos en contacto para continuar con el envio');
          }else{
            window.alerta('error','Ha ocurrido un error intentalo más tarde');
          }
        })
        .then(function(texto) {
           window.H5_loading.hide();
        })
        .catch(err => console.error(err));
        this.setState({nombre:'',correo:'',mensaje:'',telefono:'',producto:'',cantidad:'1'});
    }
  }

  render () {
    return (
      <div className="contacto">
        <Link to="inicio" className="regresar">REGRESAR</Link>
        <div id="cl"></div>
        <div className="pa_flex_2">
          <div className="de_flex_2" data-aos="fade-left">
            <form id="formulario">
              <h7>Haz un regalo</h7>
              <div id="cl"><br/></div>
              Nombre:
              <input type="text" name="nombre" onChange={this.handleChange} placeholder="Nombre:" value={this.state.nombre}/>
              Correo electrónico:
              <input type="text" name="correo" onChange={this.handleChange} placeholder="Correo:" value={this.state.correo}/>
              Teléfono:
              <input type="text" name="telefono" onChange={this.handleChange} placeholder="Teléfono:" value={this.state.telefono}/>
              Producto:
              <select name="producto" value={this.state.producto} onChange={this.handleChange}>
                <option value=""></option>
                {window._my_tequilas.map((item, i) => <option value={`${item.titulo} - ${item.marca}`} key={i}>{item.titulo} - {item.marca}</option>)}
              </select>
              Cantidad:
              <input type="number" min="1" name="cantidad" onChange={this.handleChange} placeholder="Cantidad" value={this.state.cantidad}/>
              A quién enviar:
              <textarea name="mensaje" id="area" onChange={this.handleChange} placeholder="A quién enviar (especificaciones):" value={this.state.mensaje}/>                  
              <center><div onClick={this.handleClick} className="button puntero">ENVIAR</div></center>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Contacto;