import React, { Component } from 'react';
import { Link as Ir } from 'react-scroll';
import { Link } from 'react-router-dom'
import { Accordion, AccordionItem } from 'react-sanfona';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class MenuResponsive extends Component {
  constructor() {
    super()
    this.state = {
      verMenu: 'ver_no',
      verIco: 'menu.svg',
      verCerrar: 'ver_no',
    }
    this.verMenu = this.verMenu.bind(this)
  }

  activa(e) {
    this.setState({verMenu: 'ver_no', verIco: 'menu.svg'})
  }

  verMenu() {
    if(this.state.verMenu==='ver_no'){
      this.setState({verMenu: 'ver_si', verIco: 'menu_x.svg', verCerrar: 'ver_si_cerrar'})
    }else{
      this.setState({verMenu: 'ver_no', verIco: 'menu.svg', verCerrar: 'ver_no'})
    }
  }

  render () {
    return (
      <div>
        <img src={`img/${this.state.verIco}`} alt="Menú" title="Menú" className="menu_res" height="30" onClick={this.verMenu}/>

        <div className="menu_cerrar" id={this.state.verCerrar} onClick={this.verMenu}></div>
        <div className="menu_r" id={this.state.verMenu}>
          <div className="overflow">
            <img src="img/logo.svg" alt="kanTina libre" title="kanTina libre" className="logo_s"/>
            <div id="cl"><br/></div>
            <ul>
              <li>
                <Ir to="inicio" activeClass="active" spy={true} smooth={true} duration={500} className="ir_s" onClick={this.verMenu}>Inicio</Ir>
              </li>
              <li>
                <Accordion allowMultiple={false}>
                  <AccordionItem title="Tequilas +" className="dt">
                    <div id="cl"><br/></div>
                    {
                      window._my_tequilas.map((item, i) =>
                        <div>
                          <Ir to={item.ir} activeClass="active" spy={true} smooth={true} duration={500} className="ir_s" onClick={this.verMenu}>{item.titulo} <h13>{item.marca}</h13></Ir>
                          <div id="cl"></div>
                        </div>
                      )
                    }
                  </AccordionItem>
                </Accordion>
              </li>
              <li>
                <Ir to="contacto" activeClass="active" spy={true} smooth={true} duration={500} className="ir_s" onClick={this.verMenu}>Contacto</Ir>
              </li>
              <li>
                <Link to="regalo" className="ir_s">Haz un regalo</Link>
              </li>
            </ul>
            <div id="cl"><br/><br/></div>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuResponsive;