import React, { Component } from 'react';
import { Element } from 'react-scroll';

import Menu from './Menu'
import MenuResponsive from './MenuResponsive'
import Contacto from './Contacto'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Inicio extends Component {
  componentWillMount () {
    window.H5_loading.show();
    //window.scrollTo(0, 0)
    /*
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    */
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  state = {
    sector: 1,
    producto: ''
  }

  sectorShow(e,s){
    window.H5_loading.show();
    this.setState({ 
      sector: e,
      producto: s 
    });
    window.H5_loading.hide();
  };

  render () {
    return (
      <div>
        <Element name="inicio"></Element>
        <Menu />
        <MenuResponsive />
        <div className="de_banner">
          <div className="banner">
            <div className="tenue">
              <div className="de_tenue">
                Creamos la experiencia perfecta
                <br/>
                con tequilas de primera clase
              </div>
              <div className="flex_cayo">
                Creamos la experiencia perfecta con tequilas de primera clase
              </div>
              <div className="flex_cayo">
                <center><img src="img/botellas.png" alt="Don Cayo" title="Don Cayo"/></center>
              </div>
            </div>
          </div>
        </div>
        <img src="img/botellas.png" className="botellas" alt="Don Cayo" title="Don Cayo"/>
        <div id="cl"></div>

        <div className="max_width padding_width_dos">
          {
            window._my_tequilas.map((item, i) => 
              <div key={i}>
                <Element name={item.ir}></Element>
                <div className="flex_tequilas2">
                  <center>
                    <div className="de_flex_t1" data-aos="fade-right">
                      <img src={item.logo} className="don" alt={item.marca} title={item.marca}/>
                      <div id="cl"></div>
                      <img src="img/agave.svg" className="don_agave" alt="100% Agave" title="100% Agave"/>
                      <div id="cl"></div>
                      <img src={item.imagen} className="tequila" alt={item.titulo} title={item.titulo}/>
                      <div className="circulo"></div>
                    </div>
                    <div className="de_flex_t2" data-aos="fade-left">
                      <h7>{item.titulo}</h7><h14> &nbsp; {this.state.sector===2 && this.state.producto===item.ir ? item.presentacion2 : item.presentacion}</h14>
                      <br/>
                      <h8>
                        100% Agave
                        <br/>
                        {item.sub}
                      </h8>
                      <br/><br/>
                      {item.descripcion}
                      <br/><br/>
                      <h7>Aroma</h7>
                      <br/>
                      {item.aroma}
                      <br/><br/>
                      <div className="flex_contenido">
                        <div className="de_contenido">
                          <h7>Gusto</h7>
                          <br/>
                          {item.gusto}
                          <br/><br/>
                          <h7>Barrica</h7>
                          <br/>
                          {item.barrica}
                        </div>
                        <div className="de_contenido">
                          <h7>Presentación</h7>
                          <br/>
                          {
                            item.presentacion2 ?
                              <div>
                                <div className={this.state.sector===1 && this.state.producto===item.ir ? "presentacion" : "presentacion2"} onClick={this.sectorShow.bind(this, 1, item.ir)}>{item.presentacion}</div>
                                <div className={this.state.sector===2 && this.state.producto===item.ir ? "presentacion" : "presentacion2"} onClick={this.sectorShow.bind(this, 2, item.ir)}>{item.presentacion2}</div>
                              </div>
                            :
                              item.presentacion
                          }
                          <br/><br/>
                          <h7>Disfrutar</h7>
                          <br/>
                          {item.disfrutar}
                        </div>
                      </div>
                      <br/>
                      {
                        item.colores ? 
                          <div>
                            <h7>Colores</h7>
                            <br/>
                            {item.colores}
                            <br/><br/>
                          </div>
                        :
                          null
                      }
                      <h7>Volumen de Alcohol</h7>
                      <br/>
                      {item.volumen}%
                      <br/><br/>
                      <h9>${this.state.sector===2 && this.state.producto===item.ir ? item.precio2 : item.precio} </h9><h10>MXN</h10>
                    </div>
                  </center>
                </div>
                <div className="flex_tequilas">
                  {
                    item.lugar===1 ?
                      <>
                        <div className="de_flex_t1" data-aos="fade-right">
                          <img src={item.logo} className="don" alt={item.marca} title={item.marca}/>
                          <div id="cl"></div>
                          <img src="img/agave.svg" className="don_agave" alt="100% Agave" title="100% Agave"/>
                          <div id="cl"></div>
                          <img src={item.imagen} className="tequila" alt={item.titulo} title={item.titulo}/>
                          <div className="circulo"></div>
                        </div>
                        <div className="de_flex_t2" data-aos="fade-left">
                          <h7>{item.titulo}</h7><h14> &nbsp; {this.state.sector===2 && this.state.producto===item.ir ? item.presentacion2 : item.presentacion}</h14>
                          <br/>
                          <h8>
                            100% Agave
                            <br/>
                            {item.sub}
                          </h8>
                          <br/><br/>
                          {item.descripcion}
                          <br/><br/>
                          <h7>Aroma</h7>
                          <br/>
                          {item.aroma}
                          <br/><br/>
                          <div className="flex_contenido">
                            <div className="de_contenido">
                              <h7>Gusto</h7>
                              <br/>
                              {item.gusto}
                              <br/><br/>
                              <h7>Barrica</h7>
                              <br/>
                              {item.barrica}
                            </div>
                            <div className="de_contenido">
                              <h7>Presentación</h7>
                              <br/>
                              {
                                item.presentacion2 ?
                                  <div>
                                    <div className={this.state.sector===1 && this.state.producto===item.ir ? "presentacion" : "presentacion2"} onClick={this.sectorShow.bind(this, 1, item.ir)}>{item.presentacion}</div>
                                    <div className={this.state.sector===2 && this.state.producto===item.ir ? "presentacion" : "presentacion2"} onClick={this.sectorShow.bind(this, 2, item.ir)}>{item.presentacion2}</div>
                                  </div>
                                :
                                  item.presentacion
                              }
                              <br/><br/>
                              <h7>Disfrutar</h7>
                              <br/>
                              {item.disfrutar}
                            </div>
                          </div>
                          <br/>
                          {
                            item.colores ? 
                              <div>
                                <h7>Colores</h7>
                                <br/>
                                {item.colores}
                                <br/><br/>
                              </div>
                            :
                              null
                          }
                          <h7>Volumen de Alcohol</h7>
                          <br/>
                          {item.volumen}%
                          <br/><br/>
                          <h9>${this.state.sector===2 && this.state.producto===item.ir ? item.precio2 : item.precio} </h9><h10>MXN</h10>
                        </div>
                      </>
                    :
                      <>
                        <div className="de_flex_t2" data-aos="fade-right">
                          <h7>{item.titulo}</h7><h14> &nbsp; {this.state.sector===2 && this.state.producto===item.ir ? item.presentacion2 : item.presentacion}</h14>
                          <br/>
                          <h8>
                            100% Agave
                            <br/>
                            {item.sub}
                          </h8>
                          <br/><br/>
                          {item.descripcion}
                          <br/><br/>
                          <h7>Aroma</h7>
                          <br/>
                          {item.aroma}
                          <br/><br/>
                          <div className="flex_contenido">
                            <div className="de_contenido">
                              <h7>Gusto</h7>
                              <br/>
                              {item.gusto}
                              <br/><br/>
                              <h7>Barrica</h7>
                              <br/>
                              {item.barrica}
                            </div>
                            <div className="de_contenido">
                              <h7>Presentación</h7>
                              <br/>
                              {
                                item.presentacion2 ?
                                  <div>
                                    <div className={this.state.sector===1 && this.state.producto===item.ir ? "presentacion" : "presentacion2"} onClick={this.sectorShow.bind(this, 1, item.ir)}>{item.presentacion}</div>
                                    <div className={this.state.sector===2 && this.state.producto===item.ir ? "presentacion" : "presentacion2"} onClick={this.sectorShow.bind(this, 2, item.ir)}>{item.presentacion2}</div>
                                  </div>
                                :
                                  item.presentacion
                              }
                              <br/><br/>
                              <h7>Disfrutar</h7>
                              <br/>
                              {item.disfrutar}
                            </div>
                          </div>
                          <br/>
                          {
                            item.colores ? 
                              <div>
                                <h7>Colores</h7>
                                <br/>
                                {item.colores}
                                <br/><br/>
                              </div>
                            :
                              null
                          }
                          <h7>Volumen de Alcohol</h7>
                          <br/>
                          {item.volumen}%
                          <br/><br/>
                          <h9>${this.state.sector===2 && this.state.producto===item.ir ? item.precio2 : item.precio} </h9><h10>MXN</h10>
                        </div>
                        <div className="de_flex_t1" data-aos="fade-left">
                          <img src={item.logo} className="don" alt={item.marca} title={item.marca}/>
                          <div id="cl"></div>
                          <img src="img/agave.svg" className="don_agave" alt="100% Agave" title="100% Agave"/>
                          <div id="cl"></div>
                          <img src={item.imagen} className="tequila" alt={item.titulo} title={item.titulo}/>
                          <div className="circulo"></div>
                        </div>
                      </>
                  }
                  
                </div>
                <center><img src="img/linea.svg" alt="Agave" className="agave"/></center>
                <div id="cl"></div>
              </div>
            )
          }
          <Element name="contacto"></Element>
          <Contacto />
        </div>
      </div>
    )
  }
}

export default Inicio;